export const MINIMUM_SEARCH_CHARACTERS = 2

export const WEEKS_IN_YEAR = 52.1429

export const TOTAL_FEE = 0.0025
export const LP_HOLDERS_FEE = 0.0017
export const TREASURY_FEE = 0.000225
export const BUYBACK_FEE = 0.000575

export const PCS_V2_START = 1619136000 // April 23, 2021, 12:00:00 AM
export const PCS_ETH_START = 1664130827 // Sep 23, 2022, 02:33:47 AM
export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600

export const ITEMS_PER_INFO_TABLE_PAGE = 10

// These tokens are either incorrectly priced or have some other issues that spoil the query data
// None of them present any interest as they have almost 0 daily trade volume

export const BSC_TOKEN_WHITELIST = []
export const TOKEN_BLACKLIST = [
  // These ones are copied from v1 info
  '0x1bc3ee06e346e85583100ce9d44e3dd827b6f3dd',
  '0xc0d29c412f4144cd60826a3327e81c50ad86ef5b',
  '0xa7380883acfcebbe1d43ccf3bad3391216b38a28',
  '0xd9bf0da51bb481d61b7838e6f3cd0bbda6ba425f',
]

export const ETH_TOKEN_BLACKLIST = ['0x72B169AD8aF6c4FB53056b6a2A85602Ad6863864']
export const ETH_TOKEN_WHITELIST = [
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  '0x993864E43Caa7F7F12953AD6fEb1d1Ca635B875F',
]
