import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx="20" cy="20" r="20" fill="#D7F9ED" />
      <path
        d="M10.3595 19.7172L14.554 21.3047L16.112 26.5556C16.2319 26.9219 16.5914 27.044 16.8311 26.7998L19.1081 24.846C19.3478 24.6017 19.7073 24.6017 19.947 24.846L24.1415 27.8988C24.3812 28.1431 24.8606 27.8988 24.8606 27.6546L27.9765 12.6345C28.0963 12.2682 27.7368 11.9018 27.3773 12.024L10.3595 18.7403C9.88016 18.8624 9.88016 19.4729 10.3595 19.7172ZM15.8723 20.4499L24.0217 15.321C24.1415 15.1989 24.2614 15.4432 24.1415 15.5653L17.4303 21.9152C17.1906 22.1595 17.0708 22.4037 16.9509 22.77L16.7112 24.4796C16.7112 24.7239 16.3517 24.7239 16.3517 24.4796L15.5128 21.3047C15.3929 21.0604 15.5128 20.572 15.8723 20.4499Z"
        fill="#CC9D4F"
      />
    </Svg>
  );
};

export default Icon;
