import { rzcoinTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { CAKE_MIX_LP_MAINNET } from './common'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: rzcoinTokens.cake,
    token1: rzcoinTokens.wrz,
    lpAddress: '0x2686cF4BD4e6f31C4E98FcCBF6F824d4F15182D8',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 2,
    token0: rzcoinTokens.wrz,
    token1: rzcoinTokens.usdt1,
    lpAddress: '0xcD27FD82A424318641453e39EccC6dDEe2FDb874',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 3,
    token0: rzcoinTokens.cake,
    token1: rzcoinTokens.usdt1,
    lpAddress: '0xD15049a70CC1DECE04dE355c66Ea4CCc48c4Bc09',
    feeAmount: FeeAmount.LOW,
  },
  // keep those farms on top
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'RZ',
    lpAddress: '0x74a33d9E44b81673a4F0B5a4294fA0e5e2ec5877',
    token: rzcoinTokens.syrup,
    quoteToken: rzcoinTokens.wrz,
  },
  {
    pid: 3,
    v1pid: 3,
    lpSymbol: 'RZ-STR LP',
    lpAddress: '0xd08eaFb65F26197AD73Ef61FFfc3b60fDe4264eC',
    token: rzcoinTokens.wrz,
    quoteToken: rzcoinTokens.cake,
    boosted: false,
  },
  {
    pid: 4,
    v1pid: 4,
    lpSymbol: 'RZ-RUSDT LP',
    lpAddress: '0x3ff9856d62a3e50Ab592dCeBFf83175b3C304100',
    token: rzcoinTokens.wrz,
    quoteToken: rzcoinTokens.usdt,
    boosted: false,
  },
  {
    pid: 5,
    v1pid: 5,
    lpSymbol: 'RUSDC-RZ LP',
    lpAddress: '0x83fa630C0545c0B47aAAC0D77b77921D10800f8f',
    token: rzcoinTokens.usdc,
    quoteToken: rzcoinTokens.wrz,
    boosted: false,
  },
  {
    pid: 6,
    v1pid: 6,
    lpSymbol: 'STR-RUSDT LP',
    lpAddress: '0x84a25863DE2D9Bb71739d07369B3d16987251c95',
    token: rzcoinTokens.cake,
    quoteToken: rzcoinTokens.usdt,
    boosted: false,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
