import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  // {
  //   label: t("Ecosystem"),
  //   items: [
  //     {
  //       label: t("Trade"),
  //       href: "/swap",
  //     },
  //     {
  //       label: t("Earn"),
  //       href: "/farms",
  //     },
  //     {
  //       label: t("Game"),
  //       href: "https://pancakeswap.games/",
  //     },
  //     {
  //       label: t("NFT"),
  //       href: "/nfts",
  //     },
  //     {
  //       label: t("Tokenomics"),
  //       href: "https://docs.pancakeswap.finance/governance-and-tokenomics/cake-tokenomics",
  //     },
  //     {
  //       label: t("RZ Emission Projection"),
  //       href: "https://analytics.pancakeswap.finance/",
  //     },
  //     {
  //       label: t("Merchandise"),
  //       href: "https://merch.pancakeswap.finance/",
  //     },
  //   ],
  // },
  // {
  //   label: "Business",
  //   items: [
  //     {
  //       label: t("Farms and Syrup Pools"),
  //       href: "https://docs.pancakeswap.finance/ecosystem-and-partnerships/business-partnerships/syrup-pools-and-farms",
  //     },
  //     {
  //       label: t("IFO"),
  //       href: "https://docs.pancakeswap.finance/ecosystem-and-partnerships/business-partnerships/initial-farm-offerings-ifos",
  //     },
  //     {
  //       label: t("NFT Marketplace"),
  //       href: "https://docs.pancakeswap.finance/ecosystem-and-partnerships/business-partnerships/nft-market-applications",
  //     },
  //   ],
  // },
  {
    label: t("About"),
    items: [
      {
        label: t("Contact"),
        href: "https://blog.strswap.finance/contact",
      },
      {
        label: t("Brand"),
        href: "https://blog.strswap.finance/brand",
      },
      {
        label: t("Blog"),
        href: "https://blog.strswap.finance/blog",
      },
      {
        label: t("Community"),
        href: "https://blog.strswap.finance/community",
      },
      {
        label: t("STR Emission Projection"),
        href: "https://blog.strswap.finance/str_emission_projection",
      },
      {
        label: t("Terms Of Service"),
        href: "https://blog.strswap.finance/terms_of_service",
      },
    ],
  },
  {
    label: t("Help"),
    items: [
      {
        label: t("Customer Support"),
        href: "https://blog.strswap.finance/customer_support",
      },
      {
        label: t("Troubleshooting"),
        href: "https://blog.strswap.finance/troubleshooting",
      },
      {
        label: t("Guides"),
        href: "https://blog.strswap.finance/guides",
      },
    ],
  },
  {
    label: t("Developer"),
    items: [
      {
        label: t("Github"),
        href: "#",
      },
      {
        label: t("Documentation"),
        href: "#",
      },
      {
        label: t("Bug Bounty"),
        href: "#",
      },
      {
        label: t("Audits"),
        href: "#",
      },
      {
        label: t("Careers"),
        href: "#",
      },
    ],
  },
];
