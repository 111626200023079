import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlSpace="preserve" id="Layer_1" x={0} y={0} width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
      <circle cx="13" cy="13" r="13" fill="#33B589" />
      <path d="M9.97152 6.00462C10.8701 6.00462 11.7688 6.00691 12.6697 6.00233C12.7693 6.00233 12.7994 6.02291 12.7971 6.12578C12.7901 6.80475 12.7925 7.48143 12.7971 8.16039C12.7971 8.2587 12.7693 8.27927 12.6743 8.27927C11.3241 8.27698 9.97383 8.27698 8.62358 8.27927C8.08858 8.27927 7.77591 8.58789 7.7736 9.12283C7.7736 9.28286 7.78286 9.44288 7.8454 9.59377C7.97741 9.91153 8.24607 10.003 8.56337 10.0053C9.15396 10.0075 9.74455 10.0007 10.3351 10.0121C10.7798 10.0213 11.2152 10.0898 11.6321 10.2567C12.2922 10.5219 12.6489 11.0157 12.7392 11.6947C12.8295 12.3759 12.8133 13.064 12.7647 13.7476C12.6905 14.8312 11.9587 15.6404 10.8887 15.8896C10.6154 15.9536 10.3421 15.9811 10.0642 15.9811C8.64443 15.9811 7.2247 15.9742 5.80496 15.9856C5.36955 15.9879 5.03372 15.6793 5.03604 15.2404C5.03836 14.9935 5.03372 14.7443 5.03836 14.4974C5.04762 14.0836 5.3626 13.7841 5.77949 13.7819C6.90972 13.7819 8.03994 13.7819 9.17017 13.7819C9.27439 13.7819 9.38093 13.7864 9.48283 13.7704C9.7955 13.7179 9.97847 13.5281 10.0341 13.2218C10.0711 13.0252 10.0757 12.8286 10.0248 12.6343C9.93678 12.3051 9.65191 12.0856 9.3045 12.081C8.82276 12.0787 8.33871 12.0833 7.85698 12.0787C7.46325 12.0742 7.07184 12.0444 6.69664 11.9073C5.75633 11.5598 5.16805 10.9151 5.08236 9.91153C5.0152 9.15027 4.99435 8.37986 5.10784 7.62088C5.21901 6.89162 5.61273 6.4024 6.48125 6.15093C6.82634 6.05034 7.18069 6.00005 7.54199 6.00691C7.60453 6.00691 7.66474 6.00691 7.72728 6.00691C8.47536 6.00462 9.22344 6.00462 9.97152 6.00462Z" fill="white" />
      <path d="M18.3174 16.2143C19.2067 17.4762 20.0892 18.7267 20.9716 19.9771C20.9438 20.0069 20.9113 19.9954 20.8836 19.9954C19.6375 19.9954 18.3915 19.9954 17.1455 19.9977C17.0505 19.9977 16.9972 19.9726 16.9417 19.8926C16.1148 18.7152 15.2857 17.5356 14.4519 16.3606C14.3801 16.26 14.3477 16.1594 14.35 16.0359C14.357 15.6587 14.3639 15.2815 14.35 14.9043C14.3361 14.486 14.6881 14.1636 15.1004 14.1659C15.9874 14.1728 16.8745 14.1682 17.7638 14.1682C17.8843 14.1682 18.0024 14.1568 18.1159 14.1202C18.3151 14.0562 18.4563 13.9259 18.5258 13.7339C18.6509 13.3932 18.6532 13.0435 18.5142 12.7074C18.3869 12.4011 18.1182 12.3005 17.8032 12.2982C16.8953 12.2959 15.9898 12.2982 15.0819 12.2959C14.797 12.2959 14.5793 12.1725 14.4357 11.9279C14.3732 11.8227 14.3523 11.7084 14.3523 11.5872C14.3523 11.0957 14.3546 10.6042 14.35 10.1127C14.35 10.0258 14.3755 10.0053 14.4612 10.0075C15.8647 10.0098 17.2705 10.0098 18.6741 10.0098C19.1743 10.0098 19.6676 10.0533 20.1355 10.2476C20.7515 10.5013 21.1128 10.9631 21.2588 11.5964C21.312 11.8273 21.3305 12.0627 21.3305 12.2982C21.3282 12.8697 21.3329 13.4412 21.3305 14.0128C21.3282 14.3259 21.2726 14.6323 21.1661 14.9295C21.0434 15.2724 20.7724 15.4758 20.4759 15.6519C20.0475 15.9056 19.5727 16.0336 19.084 16.1137C18.8385 16.1502 18.5884 16.1777 18.3174 16.2143Z" fill="white" />
      <path d="M16.9179 6.00462C18.1501 6.00462 19.3799 6.01376 20.612 6.00005C20.9918 5.99548 21.3462 6.3201 21.3323 6.72473C21.323 6.98763 21.3323 7.25053 21.33 7.51343C21.3277 7.99122 21.0382 8.27698 20.5518 8.27698C19.7134 8.27698 18.8773 8.27698 18.0389 8.27698C17.93 8.27698 17.8235 8.27698 17.7146 8.28841C17.3418 8.32499 17.0731 8.61304 17.0685 8.98338C17.0662 9.21199 17.0662 9.4406 17.0685 9.66921C17.0708 9.74465 17.0523 9.77437 16.9666 9.77437C16.1328 9.76979 15.299 9.76979 14.4652 9.77437C14.3657 9.77437 14.3425 9.74465 14.3448 9.6532C14.3518 9.45203 14.3494 9.24857 14.3471 9.04739C14.3425 8.57646 14.0507 8.28613 13.5759 8.27927C13.4323 8.27698 13.291 8.2747 13.1474 8.27927C13.0594 8.28384 13.0293 8.26327 13.0293 8.16725C13.0339 7.486 13.0339 6.80475 13.0293 6.12121C13.0293 6.02519 13.0548 6.00233 13.1497 6.00233C14.4097 6.00691 15.6649 6.00462 16.9179 6.00462Z" fill="white" />
      <path d="M12.0312 17.6888C12.1772 17.6888 12.3115 17.6888 12.4458 17.6888C12.8002 17.6888 13.1568 17.6796 13.5112 17.6911C13.8447 17.7002 14.1064 17.5928 14.2848 17.2887C14.9217 18.1917 15.5516 19.0856 16.1839 19.9794C16.1445 20.0069 16.1098 19.9954 16.0774 19.9954C15.3015 19.9954 14.5279 19.9954 13.7521 19.9977C13.678 19.9977 13.6339 19.9771 13.5923 19.9154C13.085 19.193 12.5732 18.4706 12.0637 17.7482C12.0498 17.7368 12.0451 17.7185 12.0312 17.6888Z" fill="white" />
      <path d="M13.2043 19.9977C12.9056 19.9977 12.63 19.9977 12.352 19.9977C12.0556 19.9977 11.7591 19.9977 11.4604 20C11.4048 20 11.3724 19.9863 11.3376 19.9383C10.835 19.2205 10.3278 18.5049 9.82525 17.7894C9.80672 17.7642 9.76271 17.7391 9.78587 17.7025C9.80672 17.6705 9.85072 17.6888 9.88315 17.6888C10.4158 17.6888 10.9485 17.6888 11.4789 17.6865C11.5345 17.6865 11.5808 17.6911 11.6179 17.7436C12.1413 18.4889 12.667 19.2342 13.2043 19.9977Z" fill="white" />
      <path d="M8.02539 17.6888C8.4307 17.6888 8.82442 17.6911 9.21583 17.6865C9.28532 17.6865 9.30848 17.7345 9.33858 17.7756C9.62577 18.1803 9.90833 18.5872 10.1932 18.9918C10.4225 19.3187 10.6541 19.6457 10.8834 19.9703C10.8649 20.0114 10.8347 19.9954 10.8116 19.9977C10.448 20 10.0867 19.9977 9.72305 20C9.66978 20 9.63041 19.9909 9.59567 19.9428C9.07919 19.2067 8.5604 18.4729 8.0416 17.7391C8.03697 17.7276 8.03465 17.7162 8.02539 17.6888Z" fill="white" />
      <path d="M7.53143 19.9977C7.23035 19.9977 6.94779 19.9977 6.66292 19.9977C6.61891 19.9977 6.60502 19.9634 6.58649 19.936C6.14644 19.3119 5.70639 18.6855 5.26634 18.0591C5.18297 17.9425 5.09959 17.8259 5 17.6888C5.23624 17.6888 5.44931 17.7025 5.66239 17.6842C5.8523 17.6682 5.95884 17.7391 6.06306 17.8899C6.52859 18.5666 7.00569 19.2342 7.47816 19.904C7.49669 19.9291 7.50827 19.9566 7.53143 19.9977Z" fill="#F0592A" />
      <path d="M6.58398 17.6911C6.86423 17.6911 7.13752 17.6933 7.41081 17.6888C7.46871 17.6888 7.48956 17.7208 7.51735 17.7596C7.96203 18.3883 8.40671 19.017 8.85139 19.6457C8.93013 19.7554 9.00656 19.8674 9.0992 19.9977C8.78885 19.9977 8.50166 19.9977 8.21448 19.9977C8.189 19.9977 8.1751 19.9817 8.16121 19.9611C7.63547 19.2159 7.10973 18.4729 6.5863 17.7276C6.58398 17.7208 6.5863 17.7116 6.58398 17.6911Z" fill="white" />
    </Svg>
  );
};

export default Icon;
