import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M20 12.8575C19.9989 14.6374 19.3333 16.3528 18.1335 17.6676C16.9337 18.9824 15.2863 19.8019 13.5139 19.9655C11.7414 20.129 9.97183 19.6249 8.55169 18.5519C7.13155 17.4789 6.16322 15.9143 5.83642 14.1646C6.97641 14.3766 8.15088 14.3079 9.25838 13.9644C10.3659 13.6209 11.3731 13.0129 12.193 12.193C13.0129 11.3731 13.6209 10.3659 13.9644 9.25838C14.3079 8.15088 14.3766 6.97641 14.1646 5.83642C15.8038 6.14155 17.2847 7.0106 18.3505 8.29293C19.4163 9.57527 19.9998 11.1901 20 12.8575ZM12.8575 5.71499C13.296 5.71541 13.7335 5.75605 14.1646 5.83642C13.9267 4.55738 13.344 3.36761 12.4793 2.39553C11.6147 1.42345 10.501 0.705994 9.25838 0.320597C8.0158 -0.0648008 6.69156 -0.103498 5.4286 0.208683C4.16563 0.520863 3.01192 1.17206 2.09199 2.09199C1.17206 3.01192 0.520863 4.16563 0.208683 5.4286C-0.103498 6.69156 -0.0648008 8.0158 0.320597 9.25838C0.705994 10.501 1.42345 11.6147 2.39553 12.4793C3.36761 13.344 4.55738 13.9267 5.83642 14.1646C5.64452 13.1337 5.68197 12.0731 5.94612 11.0583C6.21027 10.0435 6.69464 9.09928 7.36482 8.29275C8.03499 7.48622 8.87454 6.83715 9.82383 6.39164C10.7731 5.94613 11.8089 5.71511 12.8575 5.71499Z"
        fill="#00AE73"
      />
    </Svg>
  );
};

export default Icon;
