import { ChainId } from '@pancakeswap/chains'
import { chainlinkOracleCAKE } from '@pancakeswap/prediction'
import { Currency, CurrencyAmount, ERC20Token, Pair, Price, Token } from '@pancakeswap/sdk'
import { BUSD, CAKE, USDT } from '@pancakeswap/tokens'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import { FAST_INTERVAL } from 'config/constants'
import useSWR from 'swr'
import getLpAddress from 'utils/getLpAddress'
import { publicClient } from 'utils/wagmi'
import { formatUnits } from 'viem'
import { useActiveChainId } from './useActiveChainId'
import { usePairContract } from './useContract'

// for migration to bignumber.js to avoid breaking changes
export const useCakePrice = ({ enabled = true } = {}) => {
  const { chainId } = useActiveChainId()
  // Return bsc testnet cake if chain is testnet
  const cake: Token = CAKE[chainId]
  const price = usePriceByPairs(USDT[cake.chainId], cake)
  return price ? new BigNumber(price.toFixed()) : BIG_ZERO

  // const { data } = useQuery<BigNumber, Error>({
  //   queryKey: ['cakePrice'],
  //   queryFn: async () => new BigNumber(await getCakePriceFromOracle()),
  //   staleTime: FAST_INTERVAL,
  //   refetchInterval: FAST_INTERVAL,
  //   enabled,
  // })
  // return data ?? BIG_ZERO
}

export const getCakePriceFromOracle = async () => {
  const data = await publicClient({ chainId: ChainId.BSC }).readContract({
    abi: chainlinkOracleABI,
    address: chainlinkOracleCAKE[ChainId.BSC],
    functionName: 'latestAnswer',
  })

  return formatUnits(data, 8)
}

/**
 * @deprecated it's using v2 pair
 */
export const usePriceByPairs = (currencyA?: Currency, currencyB?: Currency) => {
  const [tokenA, tokenB] = [currencyA?.wrapped, currencyB?.wrapped]
  const pairAddress = getLpAddress(tokenA, tokenB)
  const pairContract = usePairContract(pairAddress as `0x${string}`)

  const { data: price } = useSWR(
    currencyA && currencyB && ['pair-price', currencyA, currencyB],
    async () => {
      if (!pairContract) {
        return null
      }
      const reserves = await pairContract.read.getReserves()
      if (!reserves) {
        return null
      }
      if (!tokenA || !tokenB) {
        return null
      }
      const [reserve0, reserve1] = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      const pair = new Pair(
        CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
        CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
      )

      return pair.priceOf(tokenB)
    },
    { dedupingInterval: FAST_INTERVAL, refreshInterval: FAST_INTERVAL },
  )

  return price
}

/**
 * @deprecated it's using v2 pair, use `useCakePriceAsBN` instead
 * @Note: only fetch from one pair
 */
export const useCakeBusdPrice = (
  { forceMainnet } = { forceMainnet: false },
): Price<ERC20Token, ERC20Token> | undefined | null => {
  const { chainId } = useActiveChainId()
  // Return bsc testnet cake if chain is testnet
  const cake: Token = CAKE[chainId]
  return usePriceByPairs(BUSD[cake.chainId], cake)
}
